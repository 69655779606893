<template>
  <router-view></router-view>
</template>


<script>

export default {
  name: "coupon-general",
  
  data: () => ({}),
};
</script>

<style>
</style>